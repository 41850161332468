import { AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllUsers(authToken: string, queryParams: any) {
    const endpoint = `${SERVER_BASE_URL}/user`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function updateUser(
    data: {
        id: string;
        firstName?: string;
        lastName?: string;
        emailId?: string;
        phoneNumber?: string;
        dateOfBirth?: string;
        passwordReset?: boolean;
        userStatus?: string;
        roles?: {
            roleId: string;
            roleType: string;
        }[];
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/user`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createUser(
    data: {
        firstName?: string;
        lastName?: string;
        dateOfBirth?: string;
        phoneNumber?: string;
        emailId?: string;
        roles?: {
            roleId: string;
            roleType: string;
        }[];
        password?: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/user`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function deleteUser(id: string, authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/user`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: [id],
        },
    });
}

export { getAllUsers, updateUser, deleteUser, createUser };
