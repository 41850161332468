import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

// account
function login(params: { emailId: string; password: string }) {
    const endpoint = `${SERVER_BASE_URL}/login`;
    console.log('::::::::::::::::::::::::::::::::::::::::::::::::::')
    console.log(api.create(`${endpoint}`, params))
    console.log('::::::::::::::::::::::::::::::::::::::::::::::::::')
    
    return api.create(`${endpoint}`, params);
}

function getRefreshToken(params: { token: string }) {
    const endpoint = `${SERVER_BASE_URL}/refreshToken`;
    return api.create(`${endpoint}`, params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, getRefreshToken };
