import { login, logout, signup, forgotPassword, getRefreshToken } from './auth';
import { getAllAssetTypes, updateAssetType, createAssetType, deleteAssetType, getAssetTypeById } from './assetType';
import { getAllAssets, updateAsset, createAsset, deleteAsset, getAssetById } from './asset';
import { getUserId, createUser, deleteUser, getAllUsers, updateUser } from './user';
import { createRole, getAllRoles, updateRole } from './role';
import {
    getAllInspectorQualifications,
    updateInspectorQualification,
    createInspectorQualification,
    deleteInspectorQualification,
} from './inspectorQualification';
import {
    assignQualification,
    getAllInspectors,
    getInspectorById,
    softdeleteQualification,
    updateQualification,
} from './inspector';
import { getAttachmentDataByIds, uploadFile } from './fileOperations';
import {
    createInspectionTemplate,
    deleteInspectionTemplate,
    getAllInspectionTemplates,
    updateInspectionTemplate,
} from './inspectionTemplate';
import { createInspection, getAllInspections, updateInspection, updateInspectionData } from './inspection';
import {
    createAdminUser,
    createOrganization,
    getAllOrganizations,
    getOrganizationById,
    updateOrganization,
} from './organization';

export {
    //auth
    login,
    logout,
    signup,
    forgotPassword,
    getRefreshToken,
    // asset types
    getAllAssetTypes,
    updateAssetType,
    createAssetType,
    deleteAssetType,
    getAssetTypeById,
    // assets
    getAllAssets,
    updateAsset,
    createAsset,
    deleteAsset,
    getAssetById,
    // users
    getUserId,
    getAllUsers,
    updateUser,
    deleteUser,
    createUser,
    // roles
    getAllRoles,
    updateRole,
    createRole,
    // inspector qualifications types
    getAllInspectorQualifications,
    updateInspectorQualification,
    createInspectorQualification,
    deleteInspectorQualification,
    // inspector
    getAllInspectors,
    getInspectorById,
    updateQualification,
    assignQualification,
    softdeleteQualification,
    // file operations
    uploadFile,
    getAttachmentDataByIds,
    // inspection template
    getAllInspectionTemplates,
    updateInspectionTemplate,
    createInspectionTemplate,
    deleteInspectionTemplate,
    // inspection
    getAllInspections,
    updateInspection,
    createInspection,
    updateInspectionData,
    // organization
    getAllOrganizations,
    getOrganizationById,
    updateOrganization,
    createOrganization,
    createAdminUser,
};
